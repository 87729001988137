<template>
  <div>
    <h1>crm sales</h1>
  </div>
</template>

<script>
export default {
  name: 'index',
};
</script>

<style scoped>

</style>
